import { put, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { getClientListReq } from '../../../api/client'

export function* getClientListApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { phone, page, size } } = action
  try {
    const { data } = yield getClientListReq({ phone, page, size })
    yield put(actions.setClientList(data.clients))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* watchClientApi() {
  yield takeLatest(actions.getClientList.type, getClientListApi)
}
