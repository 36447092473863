import { createAction, createSlice, PayloadAction, } from '@reduxjs/toolkit'

const prefix = 'pages/client'

export const clearStore = createAction(`${prefix}/clearStore`)

const clientSlice = createSlice({
  name: prefix,
  initialState: {
    isLoading: false as boolean,
    clientList: [] as any
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setClientList: (state, { payload }: PayloadAction<any>) => {
      state.clientList = payload
    }
  },
  extraReducers: {
    [clearStore.type]: () => ({
      isLoading: false,
      clientList: [],
    }),
  },
})

export const { setIsLoading, setClientList } = clientSlice.actions

export const getClientList = createAction<{ phone: string, page: number, size: number }>(`${prefix}/getClientList`)

export const clientReducer = clientSlice.reducer
